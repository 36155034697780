import React, { useState } from 'react';
import Axios from 'axios';
import Websites from '../utils/Websites';
import Ls from 'local-storage';

let GlobalContext;

const defaultState = {
    isError: false,
    isLoading: false,
    tokenKey: ''
}

const { Provider, Consumer } = GlobalContext = React.createContext(defaultState);

const GlobalProvider = props => {

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tokenKey, setTokenKey] = useState(Ls.get('tokenKey') || null);
    const [webSite, setWebSite] = useState(Ls.get('webSite') || null);

    const Authentication = async (Username, Password, Website) => {
        const ApiConfig = {
            'method': 'post',
            'url': `${Websites.CorsURL}${Websites[Website]}rest/V1/integration/admin/token`,
            'data': {
                'username': Username,
                'password': Password
            }
        }

        setIsLoading(true);
        setIsError(false);
        setWebSite(Websites[Website]);
        
        Ls.set('webSite', Websites[Website])

        // Api Call.
        await Axios(ApiConfig)
        .then(response => { setTokenKey(response.data); Ls.set('tokenKey', response.data); window.location.href = '/dashboard'; })
        .catch(error => { setIsError(true); })
        
        setIsLoading(false);
    }

    return (
        <Provider value={{ Authentication, isLoading, setIsLoading, isError, setIsError, tokenKey, webSite }}>
            {props.children}
        </Provider>
    );

}

// Export Provider component.
export default ({ element }) => (
    <GlobalProvider>
        {element}
    </GlobalProvider>
);

export { Consumer as GlobalConsumer, GlobalContext}