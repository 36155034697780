const Websites = {
    CorsURL: 'https://cors-anywhere.herokuapp.com/',
    Crimelondon: 'https://crimelondon.com/',
    Run2Me: 'https://run2me.net/',
    Presidents: 'http://pspresidents.com/',
    CavalleriaToscana: 'https://cavalleriatoscana.com/',
    ottodAme: 'https://www.ottodame.com/'
}

export default Websites;
